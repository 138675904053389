<script>
export default {
  name: 'layanan-detail',
  data() {
    return {
      service: null
    }
  },
  mounted() {
    this.getService()
  },
  methods: {
    getService() {
      this.axios.get(`service/slug/${this.$route.params.slug}`)
        .then( response => {
          this.service = response.data.result.service
        })
    }
  }
}
</script>

<template>
  <div v-if="service">
    <div class="head-for-bg mobile-for-head" :style="{ backgroundImage: `url(${$store.getters.backgroundImage})` }">
      <img :src="$store.getters.backgroundImage" class="hide-mobile" alt="" />
      <div class="for-title tittle-single">
        <h4 class="font-weight-6 width-50 font-28 w-res-100 margin-auto ">{{ service.title }}</h4>
      </div>
    </div>
    <div class="container">
      <div class="_sub-container">
        <div class="breadcump">
          <ul>
            <li class="">
              <a href="/layanan" class="hide-mobile">Layanan</a>
               <a class="hide-desktop" href="/layanan">Layanan <i class="fa fa-angle-right"></i> </a>
                  <h4 class=" hide-desktop">{{ service.title }}</h4>
            </li>
            <li class=" width-70 w-res-50 hide-mobile">
              <h4 class="elipsis">{{ service.title }}</h4></li>
          </ul>
        </div>
        <div class="content-detail-layanan">
          <p class="ln-2" v-html="service.content"></p>
        </div>
      </div>
    </div>
  </div>
</template>